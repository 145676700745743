// Fontawesome 5
import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/regular";
import "@fortawesome/fontawesome-free/js/brands";

// // Line Awesome
// import "line-awesome/dist/line-awesome/scss/line-awesome.scss";
//
// // Remix icons
// import "remixicon/fonts/remixicon.css";

// snackbar
const Snackbar = require("node-snackbar");
window.notification = function notification(
    message,
    color = "primary",
    pos = "top-center",
    duration = 2000
) {
    let customValues = {
        text: message,
        pos: pos,
        duration: duration,
    };
    if (color === "primary") {
        customValues["actionTextColor"] = "#fff";
        customValues["backgroundColor"] = "#1b55e2";
    }
    if (color === "success") {
        customValues["actionTextColor"] = "#fff";
        customValues["backgroundColor"] = "#8dbf42";
    }
    if (color === "info") {
        customValues["actionTextColor"] = "#fff";
        customValues["backgroundColor"] = "#2196f3";
    }
    if (color === "warning") {
        customValues["actionTextColor"] = "#fff";
        customValues["backgroundColor"] = "#e2a03f";
    }
    if (color === "danger") {
        customValues["actionTextColor"] = "#fff";
        customValues["backgroundColor"] = "#e7515a";
    }
    if (color === "dark") {
        customValues["actionTextColor"] = "#fff";
        customValues["backgroundColor"] = "#3b3f5c";
    }
    Snackbar.show(customValues);
};

// clipboardjs
import ClipboardJS from "clipboard";

let clipboard = new ClipboardJS(".copy-btn");

clipboard.on("success", function () {
    notification("Copied!", "dark", "top-right");
});

/*
Template: ProX - Responsive Bootstrap 4 Admin Dashboard Template
Author: iqonicthemes.in
Design and Developed by: iqonicthemes.in
NOTE: This file contains the styling for responsive Template.
*/

/*----------------------------------------------
Index Of Script
------------------------------------------------

:: Tooltip
:: Fixed Nav
:: Scroll up menu
:: Magnific Popup
:: Ripple Effect
:: Sidebar Widget
:: Page Loader
:: Owl Carousel
:: Counter
:: Progress Bar
:: Page Menu
:: Close  navbar Toggle
:: Mailbox
:: chatuser
:: chatuser main
:: Chat start
:: todo Page
:: user toggle
:: Scroll up horizontal menu
:: Data tables
:: Form Validation
:: Active Class for Pricing Table
:: Flatpicker
:: Scrollbar
:: checkout
:: Datatables
:: image-upload
:: video
:: Button
:: Pricing tab
:: menu click li
:: tab sidebar back close
:: Remove collapse panel
:: Current Time

------------------------------------------------
Index Of Script
----------------------------------------------*/

(function (jQuery) {
    "use strict";

    jQuery(document).ready(function () {
        /*---------------------------------------------------------------------
        Tooltip
        -----------------------------------------------------------------------*/
        jQuery('[data-toggle="popover"]').popover();
        jQuery('[data-toggle="tooltip"]').tooltip();

        /*---------------------------------------------------------------------
        Fixed Nav
        -----------------------------------------------------------------------*/

        $(window).on("scroll", function () {
            if ($(window).scrollTop() > 0) {
                $(".iq-top-navbar").addClass("fixed");
            } else {
                $(".iq-top-navbar").removeClass("fixed");
            }
        });

        $(window).on("scroll", function () {
            if ($(window).scrollTop() > 0) {
                $(".white-bg-menu").addClass("sticky-menu");
            } else {
                $(".white-bg-menu").removeClass("sticky-menu");
            }
        });

        /*---------------------------------------------------------------------
           Scroll up menu
        -----------------------------------------------------------------------*/
        var position = $(window).scrollTop();
        $(window).on("scroll", function () {
            var scroll = $(window).scrollTop();
            //  console.log(scroll);

            if (scroll < position) {
                $(".tab-menu-horizontal").addClass("menu-up");
                $(".tab-menu-horizontal").removeClass("menu-down");
            } else {
                $(".tab-menu-horizontal").addClass("menu-down");
                $(".tab-menu-horizontal").removeClass("menu-up");
            }
            if (scroll == 0) {
                $(".tab-menu-horizontal").removeClass("menu-up");
                $(".tab-menu-horizontal").removeClass("menu-down");
            }
            position = scroll;
        });

        /*---------------------------------------------------------------------
        Magnific Popup
        -----------------------------------------------------------------------*/
        if (typeof $.fn.magnificPopup !== typeof undefined) {
            jQuery(".popup-gallery, .icon-grid").magnificPopup({
                delegate: "a.image-popup-vertical-fit",
                type: "image",
                tLoading: "Loading image #%curr%...",
                mainClass: "mfp-img-mobile",
                gallery: {
                    enabled: true,
                    navigateByImgClick: true,
                    preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
                },
                image: {
                    tError:
                        '<a href="%url%">The image #%curr%</a> could not be loaded.',
                    titleSrc: function (item) {
                        return (
                            item.el.attr("title") +
                            "<small>by Marsel Van Oosten</small>"
                        );
                    },
                },
            });
            jQuery(".popup-youtube, .popup-vimeo, .popup-gmaps").magnificPopup({
                disableOn: 700,
                type: "iframe",
                mainClass: "mfp-fade",
                removalDelay: 160,
                preloader: false,
                fixedContentPos: false,
            });
        }

        /*---------------------------------------------------------------------
        Ripple Effect
        -----------------------------------------------------------------------*/
        jQuery(document).on("click", ".iq-waves-effect", function (e) {
            // Remove any old one
            jQuery(".ripple").remove();
            // Setup
            let posX = jQuery(this).offset().left,
                posY = jQuery(this).offset().top,
                buttonWidth = jQuery(this).width(),
                buttonHeight = jQuery(this).height();

            // Add the element
            jQuery(this).prepend("<span class='ripple'></span>");

            // Make it round!
            if (buttonWidth >= buttonHeight) {
                buttonHeight = buttonWidth;
            } else {
                buttonWidth = buttonHeight;
            }

            // Get the center of the element
            let x = e.pageX - posX - buttonWidth / 2;
            let y = e.pageY - posY - buttonHeight / 2;

            // Add the ripples CSS and start the animation
            jQuery(".ripple")
                .css({
                    width: buttonWidth,
                    height: buttonHeight,
                    top: y + "px",
                    left: x + "px",
                })
                .addClass("rippleEffect");
        });

        /*---------------------------------------------------------------------
         Sidebar Widget
         -----------------------------------------------------------------------*/

        jQuery(document).on("click", ".iq-menu > li > a", function () {
            jQuery(".iq-menu > li > a").parent().removeClass("active");
            jQuery(this).parent().addClass("active");
        });

        // Active menu
        var parents = jQuery("li.active").parents(".iq-submenu.collapse");

        parents.addClass("show");

        parents.parents("li").addClass("active");
        jQuery('li.active > a[aria-expanded="false"]').attr(
            "aria-expanded",
            "true"
        );

        /*---------------------------------------------------------------------
        FullScreen
        -----------------------------------------------------------------------*/
        jQuery(document).on("click", ".iq-full-screen", function () {
            let elem = jQuery(this);
            if (
                !document.fullscreenElement &&
                !document.mozFullScreenElement && // Mozilla
                !document.webkitFullscreenElement && // Webkit-Browser
                !document.msFullscreenElement
            ) {
                // MS IE ab version 11

                if (document.documentElement.requestFullscreen) {
                    document.documentElement.requestFullscreen();
                } else if (document.documentElement.mozRequestFullScreen) {
                    document.documentElement.mozRequestFullScreen();
                } else if (document.documentElement.webkitRequestFullscreen) {
                    document.documentElement.webkitRequestFullscreen(
                        Element.ALLOW_KEYBOARD_INPUT
                    );
                } else if (document.documentElement.msRequestFullscreen) {
                    document.documentElement.msRequestFullscreen(
                        Element.ALLOW_KEYBOARD_INPUT
                    );
                }
            } else {
                if (document.cancelFullScreen) {
                    document.cancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            }
            elem.find("i")
                .toggleClass("ri-fullscreen-line")
                .toggleClass("ri-fullscreen-exit-line");
        });

        /*---------------------------------------------------------------------
       Page Loader
       -----------------------------------------------------------------------*/
        jQuery("#load").fadeOut();
        jQuery("#loading").delay().fadeOut("");

        /*---------------------------------------------------------------------
        Owl Carousel
        -----------------------------------------------------------------------*/
        jQuery(".owl-carousel").each(function () {
            let jQuerycarousel = jQuery(this);
            jQuerycarousel.owlCarousel({
                items: jQuerycarousel.data("items"),
                loop: jQuerycarousel.data("loop"),
                margin: jQuerycarousel.data("margin"),
                nav: jQuerycarousel.data("nav"),
                dots: jQuerycarousel.data("dots"),
                autoplay: jQuerycarousel.data("autoplay"),
                autoplayTimeout: jQuerycarousel.data("autoplay-timeout"),
                navText: [
                    "<i class='fa fa-angle-left fa-2x'></i>",
                    "<i class='fa fa-angle-right fa-2x'></i>",
                ],
                responsiveClass: true,
                responsive: {
                    // breakpoint from 0 up
                    0: {
                        items: jQuerycarousel.data("items-mobile-sm"),
                        nav: false,
                        dots: true,
                    },
                    // breakpoint from 480 up
                    480: {
                        items: jQuerycarousel.data("items-mobile"),
                        nav: false,
                        dots: true,
                    },
                    // breakpoint from 786 up
                    786: {
                        items: jQuerycarousel.data("items-tab"),
                    },
                    // breakpoint from 1023 up
                    1023: {
                        items: jQuerycarousel.data("items-laptop"),
                    },
                    1199: {
                        items: jQuerycarousel.data("items"),
                    },
                },
            });
        });

        /*---------------------------------------------------------------------
        Counter
        -----------------------------------------------------------------------*/
        if (window.counterUp !== undefined) {
            const counterUp = window.counterUp["default"];
            const $counters = $(".counter");
            $counters.each(function (ignore, counter) {
                var waypoint = new Waypoint({
                    element: $(this),
                    handler: function () {
                        counterUp(counter, {
                            duration: 1000,
                            delay: 10,
                        });
                        this.destroy();
                    },
                    offset: "bottom-in-view",
                });
            });
        }

        /*---------------------------------------------------------------------
        Progress Bar
        -----------------------------------------------------------------------*/
        // jQuery('.iq-progress-bar > span').each(function() {
        //     let progressBar = jQuery(this);
        //     let width = jQuery(this).data('percent');
        //     progressBar.css({
        //         'transition': 'width 2s'
        //     });
        //
        //     setTimeout(function() {
        //         progressBar.appear(function() {
        //             progressBar.css('width', width + '%');
        //         });
        //     }, 100);
        // });
        //
        // jQuery('.progress-bar-vertical > span').each(function () {
        //     let progressBar = jQuery(this);
        //     let height = jQuery(this).data('percent');
        //     progressBar.css({
        //         'transition': 'height 2s'
        //     });
        //     setTimeout(function () {
        //         progressBar.appear(function () {
        //             progressBar.css('height', height + '%');
        //         });
        //     }, 100);
        // });

        /*---------------------------------------------------------------------
        Page Menu
        -----------------------------------------------------------------------*/
        jQuery(document).on("click", ".wrapper-menu", function () {
            jQuery(this).toggleClass("open");
        });

        jQuery(document).on("click", ".wrapper-menu", function () {
            jQuery("body").toggleClass("sidebar-main");
        });

        /*---------------------------------------------------------------------
         Close  navbar Toggle
         -----------------------------------------------------------------------*/

        jQuery(".close-toggle").on("click", function () {
            jQuery(".h-collapse.navbar-collapse").collapse("hide");
        });

        /*---------------------------------------------------------------------
        Mailbox
        -----------------------------------------------------------------------*/
        jQuery(document).on("click", "ul.iq-email-sender-list li", function () {
            jQuery(this).next().addClass("show");
            // jQuery('.mail-box-detail').css('filter','blur(4px)');
        });

        jQuery(document).on("click", ".email-app-details li h4", function () {
            jQuery(".email-app-details").removeClass("show");
        });

        /*---------------------------------------------------------------------
        chatuser
        -----------------------------------------------------------------------*/
        jQuery(document).on(
            "click",
            ".chat-head .chat-user-profile",
            function () {
                jQuery(this).parent().next().toggleClass("show");
            }
        );
        jQuery(document).on("click", ".user-profile .close-popup", function () {
            jQuery(this).parent().parent().removeClass("show");
        });

        /*---------------------------------------------------------------------
        chatuser main
        -----------------------------------------------------------------------*/
        jQuery(document).on("click", ".chat-search .chat-profile", function () {
            jQuery(this).parent().next().toggleClass("show");
        });
        jQuery(document).on("click", ".user-profile .close-popup", function () {
            jQuery(this).parent().parent().removeClass("show");
        });

        /*---------------------------------------------------------------------
        Chat start
        -----------------------------------------------------------------------*/
        jQuery(document).on("click", "#chat-start", function () {
            jQuery(".chat-data-left").toggleClass("show");
        });
        jQuery(document).on("click", ".close-btn-res", function () {
            jQuery(".chat-data-left").removeClass("show");
        });
        jQuery(document).on("click", ".iq-chat-ui li", function () {
            jQuery(".chat-data-left").removeClass("show");
        });
        jQuery(document).on("click", ".sidebar-toggle", function () {
            jQuery(".chat-data-left").addClass("show");
        });

        /*---------------------------------------------------------------------
        todo Page
        -----------------------------------------------------------------------*/
        jQuery(document).on("click", ".todo-task-list > li > a", function () {
            jQuery(".todo-task-list li").removeClass("active");
            jQuery(".todo-task-list .sub-task").removeClass("show");
            jQuery(this).parent().toggleClass("active");
            jQuery(this).next().toggleClass("show");
        });
        jQuery(document).on(
            "click",
            ".todo-task-list > li li > a",
            function () {
                jQuery(".todo-task-list li li").removeClass("active");
                jQuery(this).parent().toggleClass("active");
            }
        );

        /*---------------------------------------------------------------------
        user toggle
        -----------------------------------------------------------------------*/
        jQuery(document).on("click", ".iq-user-toggle", function () {
            jQuery(this).parent().addClass("show-data");
        });

        jQuery(document).on("click", ".close-data", function () {
            jQuery(".iq-user-toggle").parent().removeClass("show-data");
        });
        jQuery(document).on("click", function (event) {
            var $trigger = jQuery(".iq-user-toggle");
            if (
                $trigger !== event.target &&
                !$trigger.has(event.target).length
            ) {
                jQuery(".iq-user-toggle").parent().removeClass("show-data");
            }
        });
        /*-------hide profile when scrolling--------*/
        jQuery(window).scroll(function () {
            let scroll = jQuery(window).scrollTop();
            if (
                scroll >= 10 &&
                jQuery(".iq-user-toggle").parent().hasClass("show-data")
            ) {
                jQuery(".iq-user-toggle").parent().removeClass("show-data");
            }
        });
        let Scrollbar = window.Scrollbar;
        if (jQuery(".data-scrollbar").length) {
            Scrollbar.init(document.querySelector(".data-scrollbar"), {
                continuousScrolling: false,
            });
        }

        /*---------------------------------------------------------------------
           Scroll up horizontal menu
        -----------------------------------------------------------------------*/
        var position = $(window).scrollTop();
        $(window).scroll(function () {
            var scroll = $(window).scrollTop();

            if (scroll < position) {
                $(".bg-nav-horizontal").addClass("menu-up");
                $(".bg-nav-horizontal").removeClass("menu-down");
            } else {
                $(".bg-nav-horizontal").addClass("menu-down");
                $(".bg-nav-horizontal").removeClass("menu-up");
            }
            if (scroll == 0) {
                $(".bg-nav-horizontal").removeClass("menu-up");
                $(".bg-nav-horizontal").removeClass("menu-down");
            }
            position = scroll;
        });

        /*---------------------------------------------------------------------
        Data tables
        -----------------------------------------------------------------------*/
        if ($.fn.DataTable) {
            $(".data-table").DataTable();
        }

        /*---------------------------------------------------------------------
        Form Validation
        -----------------------------------------------------------------------*/

        // Example starter JavaScript for disabling form submissions if there are invalid fields
        window.addEventListener(
            "load",
            function () {
                // Fetch all the forms we want to apply custom Bootstrap validation styles to
                var forms = document.getElementsByClassName("needs-validation");
                // Loop over them and prevent submission
                var validation = Array.prototype.filter.call(
                    forms,
                    function (form) {
                        form.addEventListener(
                            "submit",
                            function (event) {
                                if (form.checkValidity() === false) {
                                    event.preventDefault();
                                    event.stopPropagation();
                                }
                                form.classList.add("was-validated");
                            },
                            false
                        );
                    }
                );
            },
            false
        );

        /*---------------------------------------------------------------------
         Active Class for Pricing Table
         -----------------------------------------------------------------------*/
        jQuery("#my-table tr th").click(function () {
            jQuery("#my-table tr th").children().removeClass("active");
            jQuery(this).children().addClass("active");
            jQuery("#my-table td").each(function () {
                if (jQuery(this).hasClass("active")) {
                    jQuery(this).removeClass("active");
                }
            });
            var col = jQuery(this).index();
            jQuery(
                "#my-table tr td:nth-child(" + parseInt(col + 1) + ")"
            ).addClass("active");
        });

        /*------------------------------------------------------------------
        Flatpicker
        * -----------------------------------------------------------------*/
        if (jQuery(".date-input").hasClass("basicFlatpickr")) {
            jQuery(".basicFlatpickr").flatpickr();
            jQuery("#inputTime").flatpickr({
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
            });
            jQuery("#inputDatetime").flatpickr({
                enableTime: true,
            });
            jQuery("#inputWeek").flatpickr({
                weekNumbers: true,
            });
            jQuery("#inline-date").flatpickr({
                inline: true,
            });
            jQuery("#inline-date1").flatpickr({
                inline: true,
            });
        }

        /*---------------------------------------------------------------------
        Scrollbar
        -----------------------------------------------------------------------*/

        jQuery(window)
            .on("resize", function () {
                if (jQuery(this).width() <= 1299) {
                    jQuery("#salon-scrollbar").addClass("data-scrollbar");
                } else {
                    jQuery("#salon-scrollbar").removeClass("data-scrollbar");
                }
            })
            .trigger("resize");

        jQuery(".data-scrollbar").each(function () {
            var attr = $(this).attr("data-scroll");
            if (typeof attr !== typeof undefined && attr !== false) {
                let Scrollbar = window.Scrollbar;
                var a = jQuery(this).data("scroll");
                Scrollbar.init(
                    document.querySelector('div[data-scroll= "' + a + '"]')
                );
            }
        });

        /*---------------------------------------------------------------------
           checkout
        -----------------------------------------------------------------------*/

        jQuery(document).ready(function () {
            jQuery("#place-order").click(function () {
                jQuery("#cart").removeClass("show");
                jQuery("#address").addClass("show");
            });
            jQuery("#deliver-address").click(function () {
                jQuery("#address").removeClass("show");
                jQuery("#payment").addClass("show");
            });
        });

        /*---------------------------------------------------------------------
          Datatables
       -----------------------------------------------------------------------*/
        if (jQuery(".data-tables").length) {
            $(".data-tables").DataTable();
        }

        /*---------------------------------------------------------------------
        image-upload
        -----------------------------------------------------------------------*/

        $(".form_gallery-upload").on("change", function () {
            var length = $(this).get(0).files.length;
            var galleryLabel = $(this).attr("data-name");

            if (length > 1) {
                $(galleryLabel).text(length + " files selected");
            } else {
                $(galleryLabel).text($(this)[0].files[0].name);
            }
        });

        /*---------------------------------------------------------------------
            video
          -----------------------------------------------------------------------*/
        $(document).ready(function () {
            $(".form_video-upload input").change(function () {
                $(".form_video-upload p").text(
                    this.files.length + " file(s) selected"
                );
            });
        });

        /*---------------------------------------------------------------------
        Button
        -----------------------------------------------------------------------*/

        jQuery(".qty-btn").on("click", function () {
            var id = jQuery(this).attr("id");

            var val = parseInt(jQuery("#quantity").val());

            if (id == "btn-minus") {
                if (val != 0) {
                    jQuery("#quantity").val(val - 1);
                } else {
                    jQuery("#quantity").val(0);
                }
            } else {
                jQuery("#quantity").val(val + 1);
            }
        });
        if ($.fn.select2 !== undefined) {
            $("#single").select2({
                placeholder: "Select a Option",
                allowClear: true,
            });
            $("#multiple").select2({
                placeholder: "Select a Multiple Option",
                allowClear: true,
            });
            $("#multiple2").select2({
                placeholder: "Select a Multiple Option",
                allowClear: true,
            });
        }

        /*---------------------------------------------------------------------
        Pricing tab
        -----------------------------------------------------------------------*/
        jQuery(window).on("scroll", function (e) {
            // Pricing Pill Tab
            var nav = jQuery("#pricing-pills-tab");
            if (nav.length) {
                var contentNav = nav.offset().top - window.outerHeight;
                if (jQuery(window).scrollTop() >= contentNav) {
                    e.preventDefault();
                    jQuery("#pricing-pills-tab li a").removeClass("active");
                    jQuery(
                        "#pricing-pills-tab li a[aria-selected=true]"
                    ).addClass("active");
                }
            }
        });

        /*---------------------------------------------------------------------
       menu click li
       -----------------------------------------------------------------------*/

        jQuery(".two-sidebar .iq-sidebar-small").on("mouseover", function () {
            jQuery(".two-sidebar .iq-sidebar").addClass("iq-sidebar-hover");
        });

        jQuery(".two-sidebar .iq-sidebar-small").on("mouseleave", function () {
            jQuery(".two-sidebar .iq-sidebar").removeClass("iq-sidebar-hover");
        });

        if (jQuery(".two-sidebar").find("li.active").length > 0) {
            const active = jQuery(".two-sidebar")
                .find("li.active")
                .closest(".tab-pane");
            jQuery('.nav-pills a[href="#' + active.attr("id") + '"]').tab(
                "show"
            );
        }

        /*---------------------------------------------------------------------
        tab sidebar back close
        -----------------------------------------------------------------------*/

        jQuery(".iq-tab-toggle").on("click", function () {
            jQuery(this)
                .fadeOut()
                .prev(".tab-scrollbar-data")
                .addClass("tab-sidebar-close");
            jQuery(".close-setting-sidebar").removeClass("active");
            jQuery("body").removeClass("setting-sidebar-open");
        });
        jQuery(".iq-tab-toggle").on("click", function () {
            if (jQuery(this).hasClass("active") === true) {
                jQuery(".tab-scrollbar-data").addClass("tab-sidebar-close");
                jQuery(this).removeClass("active");
                jQuery("body").removeClass("tab-sidebar-data-open");
                jQuery(".iq-tab-toggle").fadeOut();
            } else {
                jQuery(".tab-scrollbar-data").removeClass("tab-sidebar-close");
                jQuery(this).addClass("active");
                jQuery("body").addClass("tab-sidebar-data-open");
                if (jQuery("#hidebackdrop").is(":checked") != true) {
                    jQuery(".iq-tab-toggle").fadeIn();
                }
            }
        });

        /*---------------------------------------------------------------------
        Remove collapse panel
        -----------------------------------------------------------------------*/

        jQuery(window)
            .bind("resize", function () {
                if (jQuery(this).width() <= 1199) {
                    jQuery(".iq-navbar-callapse-menu .collapse").removeClass(
                        "show"
                    );
                } else {
                    jQuery(".iq-navbar-callapse-menu .collapse").addClass(
                        "show"
                    );
                }
            })
            .trigger("resize");

        /*---------------------------------------------------------------------
        Current Time
        -----------------------------------------------------------------------*/
        setInterval(function () {
            var now = new Date(Date.now());
            var formatted =
                now.getHours() +
                ":" +
                now.getMinutes() +
                ":" +
                now.getSeconds();
            jQuery(".current-time").html(formatted);
        }, 1000);

        /*---------------------------------------------------------------------
        List and Grid
        -----------------------------------------------------------------------*/
        $(".list-grid-toggle").click(function () {
            var txt = $(".icon").hasClass("icon-grid") ? "List" : "Grid";
            $(".icon").toggleClass("icon-grid");
            $(".label").text(txt);
        });

        /*---------- */
        $(".dropdown-menu li a").click(function () {
            var selHtml = $(this).html();
            var selName = $.trim($(this).text());
            $(this).parents(".btn-group").find(".search-replace").html(selHtml);
            $(this).parents(".btn-group").find(".search-query").val(selName);
        });
    });
})(jQuery);

// create album
if ($(".open-new-album-dialogue").length > 0) {
    $(".open-new-album-dialogue").on("click", function () {
        $("#create-new-album input").removeClass("is-invalid");
        $("#create-album-modal").modal("show");
    });
}

$.ajaxSetup({
    headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
    },
});

const spinner = `<div class="spinner-border text-light spinner-border-sm" role="status"></div>`;

// submit new album form
$("#create-new-album").on("submit", function (e) {
    e.preventDefault();
    const current_url = $(this).data("current-url");
    const data = $(this).serializeArray();
    $("#create-new-album input, #create-new-album button").attr(
        "disabled",
        true
    );
    $.ajax({
        url: current_url,
        type: "POST",
        data: data,
        beforeSend: function () {
            $("#create-new-album input").removeClass("is-invalid");
            $("#create-new-album button[type=submit]").html(spinner);
        },
        success: function (data) {
            $("#create-new-album input, #create-new-album button").attr(
                "disabled",
                false
            );
            $("#create-new-album button[type=submit]").html("Create");
            if (data.error && data.form) {
                let messages = data.messages;
                Object.keys(messages).forEach(function (key) {
                    $("#create-new-album #" + key).addClass("is-invalid");
                    $("#create-new-album ." + key + "-feedback").text(
                        messages[key][0]
                    );
                });
            } else {
                if (data.error) {
                    notification(data.messages, "danger");
                } else {
                    $("#create-album-modal").modal("hide");
                    notification(data.messages, "success");
                    setTimeout(function () {
                        location.reload();
                    }, 3000);
                }
            }
        },
    });
});

// delete album
$(".delete-album").on("click", function (e) {
    e.preventDefault();
    const album_id = $(this).data("album-id");
    $("#delete_album_modal input#album_id").val(album_id);
    $("#delete_album_modal").modal("show");
});

$("#album_delete_form").on("submit", function (e) {
    e.preventDefault();
    const current_url = $(this).data("current-url");
    const data = $(this).serializeArray();
    $("#album_delete_form input, #album_delete_form button").attr(
        "disabled",
        true
    );
    $.ajax({
        url: current_url,
        type: "POST",
        data: data,
        beforeSend: function () {
            $("#album_delete_form button[type=submit]").html(spinner);
        },
        success: function (data) {
            $("#album_delete_form input, #album_delete_form button").attr(
                "disabled",
                false
            );
            $("#album_delete_form button[type=submit]").html("Delete");
            if (data.error) {
                notification(data.message, "danger");
            } else {
                notification(data.message, "success");
                $("#delete_album_modal").modal("hide");
                setTimeout(function () {
                    location.reload();
                }, 3000);
            }
        },
    });
});

// update an album
$(".update-album").on("click", function (e) {
    e.preventDefault();
    const url = $(this).data("url");
    const album_id = $(this).data("album-id");
    $.ajax({
        url: url,
        type: "POST",
        data: {
            album_id: album_id,
        },
        success: function (data) {
            if (data.error) {
                notification(data.message, "danger");
            } else {
                $("#update-album input[id=album_id]").val(data.album.id);
                $("#update-album input[id=name]").val(data.album.name);
                $("#update-album-modal").modal("show");
            }
        },
    });
});

$("#update-album").on("submit", function (e) {
    e.preventDefault();
    const url = $(this).data("current-url");
    const data = $(this).serializeArray();
    $("#update-album input, #update-album button").attr("disabled", true);
    $.ajax({
        url: url,
        type: "POST",
        data: data,
        beforeSend: function () {
            $("#update-album input").removeClass("is-invalid");
            $("#update-album button[type=submit]").html(spinner);
        },
        success: function (data) {
            $("#update-album input, #update-album button").attr(
                "disabled",
                false
            );
            $("#update-album button[type=submit]").html("Update");
            if (data.error && data.form) {
                let messages = data.messages;
                Object.keys(messages).forEach(function (key) {
                    $("#update-album #" + key).addClass("is-invalid");
                    $("#update-album ." + key + "-feedback").text(
                        messages[key][0]
                    );
                });
            } else {
                if (data.error) {
                    notification(data.messages, "danger");
                } else {
                    $("#update-album-modal").modal("hide");
                    notification(data.messages, "success");
                    setTimeout(function () {
                        location.reload();
                    }, 3000);
                }
            }
        },
    });
});
